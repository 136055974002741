<template>
  <GrModal
    name="Modal-view-simulator"
    id="Modal-view-simulator"
    idModal="Modal-view-simulator"
    ref="Modal-view-simulator"
    size="xl"
    :title="filterTitle"
    @shown="getPlan(selected_simulation)"
  >
    <div class="simulator" v-if="!loading && version === 1">
      <div>
        <p class="title">Marketing</p>
        <div class="container-all-marketing">
          <!-- Investimento  -->
          <div class="container-marketing">
            <div class="container-label">
              <span id="investment" class="name">Investimento</span>
              <span disabled id="investment" class="value"
                >{{ investimento | formatMoney }}</span
              >
            </div>
            <input
              disabled
              ref="investment"
              id="custom-range"
              type="range"
              min="0"
              max="50000"
              data-max="50000"
              v-model="investimento"
            />
          </div>

          <!-- CPC -->
          <div class="container-marketing">
            <div class="container-label">
              <span id="investment" class="name">CPC</span>
              <span id="investment" class="value"
                >{{ cpc | formatMoney }}</span
              >
            </div>
            <input
              disabled
              ref="clicks"
              id="custom-range"
              type="range"
              min="0"
              max="10"
              data-max="10"
              step="0.01"
              v-model="cpc"
            />
          </div>
          <!-- Total Cliques -->
          <div class="container-marketing off">
            <div class="container-label">
              <span id="investment" class="off">Total Cliques</span>
              <span id="investment" class="off">{{
                (investimento / cpc) | formatNumber
              }}</span>
            </div>
          </div>
          <!-- Taxa Clique por Lead -->
          <div class="container-marketing">
            <div class="container-label">
              <span id="investment" class="name">Taxa Clique por Lead</span>
              <span id="investment" class="value">{{ percentage_click }}%</span>
            </div>
            <input
              disabled
              ref="clicks"
              id="custom-range"
              type="range"
              min="0"
              max="100"
              data-max="100"
              step="1"
              v-model="percentage_click"
            />
          </div>
          <!-- CPL  -->
          <div class="container-marketing off">
            <div class="container-label">
              <span id="investment" class="off">CPL</span>
              <span id="investment" class="off"
                >
                {{
                  (investimento /
                    ((investimento / cpc / 100) * percentage_click))
                    | formatMoney
                }}</span
              >
            </div>
          </div>
          <!-- Total Leads  -->
          <div class="container-marketing off">
            <div class="container-label">
              <span id="investment" class="off">Total Leads</span>
              <span id="investment" class="off">{{
                ((investimento / cpc / 100) * percentage_click) | formatNumber
              }}</span>
            </div>
          </div>
          <!-- Leads   -->
          <div class="container-marketing">
            <div class="container-label">
              <span id="investment" class="name">Leads </span>
              <span id="investment" class="value">{{ leads }}%</span>
            </div>
            <input
              disabled
              ref="leads"
              id="custom-range"
              type="range"
              min="0"
              max="1"
              data-max="1"
              step="0.01"
              v-model="leads"
            />
          </div>
          <!-- Quantidade de Vendas  -->
          <div class="container-marketing off">
            <div class="container-label">
              <span id="investment" class="off">Quantidade de Vendas</span>
              <span id="investment" class="off">{{
                ((investimento / cpc / 100) * percentage_click * leads)
                  | formatNumber
              }}</span>
            </div>
          </div>
          <!-- CPV  -->
          <div class="container-marketing off">
            <div class="container-label">
              <span id="investment" class="off">CPV</span>
              <span id="investment" class="off"
                >
                {{
                  (investimento /
                    ((investimento / cpc / 100) * percentage_click * leads))
                    | formatMoney
                }}</span
              >
            </div>
          </div>
        </div>
      </div>
      <div>
        <p class="title">Vendas</p>
        <div class="container-all-marketing">
          <!-- Venda Total  -->
          <div class="container-marketing total">
            <div class="container-label">
              <div style="display: flex; gap: 10px; align-items: center">
                <img src="@/assets/img/icons/money.svg" />
                <span id="investment" class="lucro">Lucro Total</span>
              </div>
              <span
                id="investment"
                class="value"
                ref="totalprofit"
                style="cursor: default"
                >
                {{
                  ((investimento / cpc / 100) *
                    percentage_click *
                    leads *
                    (venda - (venda / 100) * taxa_cartao) -
                    calculaCPV())
                    | formatMoney
                }}</span
              >
            </div>
          </div>
          <!-- Valor Venda Bruta  -->
          <div class="container-marketing">
            <div class="container-label">
              <span id="investment" class="name">Valor Venda Bruta</span>
              <span id="investment" class="value"
                >{{ venda | formatMoney }}</span
              >
            </div>
            <input
              disabled
              ref="sale2"
              id="custom-range"
              type="range"
              min="0"
              max="100000"
              data-max="100000"
              v-model="venda"
            />
          </div>
          <!-- Taxa de Cartão  -->
          <div class="container-marketing">
            <div class="container-label">
              <span id="investment" class="name">Taxa de Cartão</span>
              <span id="investment" class="value">{{ taxa_cartao }}%</span>
            </div>
            <input
              disabled
              ref="card2"
              id="custom-range"
              type="range"
              min="0"
              max="10"
              step="0.01"
              data-max="10"
              v-model="taxa_cartao"
            />
          </div>
          <!-- Valor Líquido   -->
          <div class="container-marketing off">
            <div class="container-label">
              <span id="investment" class="off">Valor Líquido </span>
              <span id="investment" class="off"
                >
                {{ (venda - (venda / 100) * taxa_cartao) | formatMoney }}</span
              >
            </div>
          </div>
          <!-- Total Vendas   -->
          <div class="container-marketing off">
            <div class="container-label">
              <span id="investment" class="off">Total Vendas </span>
              <span id="investment" class="off"
                >
                {{
                  ((investimento / cpc / 100) *
                    percentage_click *
                    leads *
                    (venda - (venda / 100) * taxa_cartao))
                    | formatMoney
                }}</span
              >
            </div>
          </div>
          <!-- Custo produto/serviço + impostos  -->
          <div class="container-marketing">
            <div class="container-label">
              <span id="investment" class="name"
                >Custo produto/serviço + impostos</span
              >
              <span id="investment" class="value"
                >{{ imposto_producao | formatMoney }}</span
              >
            </div>
            <input
              disabled
              ref="poduction"
              id="custom-range"
              type="range"
              min="0"
              max="5000"
              data-max="5000"
              v-model="imposto_producao"
            />
          </div>
          <!-- Custo produto + CPV    -->
          <div class="container-marketing off">
            <div class="container-label">
              <span id="investment" class="off">Custo produto + CPV </span>
              <span id="investment" class="off"
                >{{ calculaCPV() | formatMoney }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="simulator" v-if="!loading && version === 2">
      <div>
        <!-- <p class="title">Marketing</p> -->
        <div class="container-all-marketing">
          <!-- Quantos quero faturar ?  -->
          <div class="container-marketing">
            <div class="container-label">
              <span id="investment" class="name">Quanto quer faturar?</span>
              <span id="investment" class="value"
                >{{ profit | formatMoney }}</span
              >
            </div>
            <input
              disabled
              ref="profit2"
              id="custom-range"
              type="range"
              min="0"
              max="500000"
              data-max="500000"
              v-model="profit"
            />
          </div>
          <!-- Preço do produto  -->
          <div class="container-marketing">
            <div class="container-label">
              <span id="investment" class="name">Preço do produto</span>
              <span id="investment" class="value"
                >{{ product | formatMoney }}</span
              >
            </div>
            <input
              disabled
              ref="product2"
              id="custom-range"
              type="range"
              min="0"
              max="15000"
              data-max="15000"
              v-model="product"
            />
          </div>
          <!-- A cada quantos leads sai uma venda?  -->
          <div class="container-marketing">
            <div class="container-label">
              <span id="investment" class="name"
                >A cada quantos leads sai uma venda?</span
              >
              <span id="investment" class="value">{{
                leads | formatNumber
              }}</span>
            </div>
            <input
              disabled
              ref="leads2"
              id="custom-range"
              type="range"
              min="1"
              max="50"
              data-max="50"
              v-model="leads"
            />
          </div>
          <!-- Qual é o preço médio do lead?   -->
          <div class="container-marketing">
            <div class="container-label">
              <span id="investment" class="name"
                >Qual é o preço médio do lead?
              </span>
              <span id="investment" class="value"
                >{{ lead_value | formatMoney }}</span
              >
            </div>
            <input
              disabled
              ref="lead_value2"
              id="custom-range"
              type="range"
              min="0"
              max="100"
              step="0.01"
              data-max="100"
              v-model="lead_value"
            />
          </div>
          <!-- Conversão -->
          <div class="container-marketing off" style="padding: 23.33px">
            <div class="container-label">
              <span id="investment" class="off">Conversão</span>
              <span id="investment" class="off">{{
                ((profit / product / (leads * (profit / product))) * 100)
                  | formatNumber
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <div>
        <!-- <p class="title">Vendas</p> -->
        <div class="container-all-marketing">
          <!-- LUCRO  -->

          <div class="container-marketing total">
            <div class="container-label">
              <div
                style="
                  display: flex;
                  gap: 10px;
                  align-items: center;
                  width: 100%;
                "
              >
                <img src="@/assets/img/icons/money.svg" />
                <span id="investment" class="lucro">Lucro</span>
                <span
                  id="investment"
                  ref="profit"
                  class="value"
                  style="
                    cursor: default;
                    display: flex;
                    align-items: end;
                    justify-content: end;
                    width: 100%;
                  "
                  >{{ calcProfit() | formatMoney }}</span
                >
              </div>
            </div>
          </div>
          <!-- Quantas vendas preciso fazer? -->
          <div class="container-marketing off" style="padding: 23.33px">
            <div class="container-label">
              <span id="investment" class="off"
                >Quantas vendas preciso fazer?</span
              >
              <span id="investment" class="off">{{
                (profit / product) | formatNumber
              }}</span>
            </div>
          </div>
          <!-- Quantos leads preciso captar? -->
          <div class="container-marketing off" style="padding: 23.33px">
            <div class="container-label">
              <span id="investment" class="off"
                >Quantos leads preciso captar?</span
              >
              <span id="investment" class="off">{{
                (leads * (profit / product)) | formatNumber
              }}</span>
            </div>
          </div>
          <!-- Quantos vou ter que investir?   -->
          <div class="container-marketing off">
            <div class="container-label">
              <span id="investment" class="off"
                >Quantos vou ter que investir?
              </span>
              <span id="investment" class="off"
                >
                {{
                  (leads * (profit / product) * lead_value) | formatMoney
                }}</span
              >
            </div>
          </div>
          <!-- ROI   -->
          <div class="container-marketing off">
            <div class="container-label">
              <span id="investment" class="off">ROI </span>
              <span id="investment" class="off">{{
                calcRoi() | formatMoney
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <template v-slot:footer="{ cancel }">
      <BaseButton variant="link-info" class="mr-4" @click="cancel">
        Fechar
      </BaseButton>
      <!-- <BaseButton variant="primary" :disabled="loading" @click="onsubmit">
        Salvar
      </BaseButton> -->
    </template>

    <div class="d-flex justify-content-center" v-if="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>
  </GrModal>
</template>

<script>
import { Money } from "v-money";
import DateRangePicker from "vue2-daterange-picker";
import Multiselect from "vue-multiselect";
export default {
  props: ["selected_simulation"],
  components: {
    Money,
    Multiselect,
    DateRangePicker,
  },
  data() {
    return {
      version: 1,
      // datas v2 -->
      lead_value: 8.0,
      leads: 10,
      product: 3500,
      profit: 10000,
      // datas v1 -->
      percentage_click: 50,
      cpc: 2.5,
      simulator_data: {},
      loading: false,
      taxa_click: 0,
      calcualLead: 0,
      leads: 0.5,
      imposto_producao: 150,
      taxa_cartao: 5,
      venda: 1200,
      investimento: 5000,
      investimento2: 5000,
      client: {
        width: 0,
      },
      all_plans: {},
    };
  },
  filters: {
    formatMoney(number) {
      if (typeof number !== "undefined" && number && number != 0) {
        number = parseFloat(number);
        const formato = {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
          useGrouping: true,
        };
        return 'R$ '+ number.toLocaleString("pt-BR", formato);
      }
      return "R$ 0,00";
    },
    formatNumber(number) {
      if (typeof number !== "undefined" && number) {
        return number.toLocaleString("pt-BR", {
          maximumFractionDigits: 0,
          useGrouping: true,
        });
      }
      return "0";
    },
  },
  computed: {
    filterTitle() {
      if (this.all_plans) {
        return `Visualizar - ${this.all_plans.title}`;
      } else {
        return "Visualizar -";
      }
    },
  },
  methods: {
    // v2 -->
    calcProfit() {
      var i9 = this.leads * (this.profit / this.product) * this.lead_value;
      var result = this.profit - i9;
      return result;
    },
    calcRoi() {
      var i9 = this.leads * (this.profit / this.product) * this.lead_value;
      var calc1 = this.profit - i9;
      var result = calc1 / i9;
      return result;
    },
    // v1 -->
    calculaCPV() {
      var quatidade_vendas =
        (this.investimento / this.cpc / 100) *
        this.percentage_click *
        this.leads;

      return (
        quatidade_vendas * this.imposto_producao + parseFloat(this.investimento)
      );
    },
    getMeta(key){
      return this.all_plans.metas.find(meta => meta.meta_key == key)?.meta_value
    },
    getPlan(plan) {
      this.all_plans = plan;
      // Cliques -->>
      this.percentage_click = this.getMeta("cliques");
      // Preço do produto -->>
      this.lead_value = this.getMeta("cpl");
      // Leads -->>
      this.leads = this.getMeta("leads");
      // Imposto -->>
      this.imposto_producao = this.getMeta("imposto_producao");
      // Cartao -->>
      this.taxa_cartao = this.getMeta("taxa_cartao");
      // CPC -->>
      this.cpc = this.getMeta("cpc");
      // Venda -->>
      this.venda = this.getMeta("venda");
      this.product = this.getMeta("venda");
      // Investimento -->>
      var investimento = this.getMeta("investimento")
      investimento = investimento
        .replace("R$ ", "")
        .replace(",", ".")
        .replace(".", "");
      this.investimento = investimento;
      this.investimento2 = investimento;
      // As variaveis acima definem o modelo v1
      // As variaveis abaixo definem o modelo v2
      if (this.cpc == "v2") {
        this.version = 2;
        this.profit = investimento;
      } else {
        this.version = 1;
      }
      setTimeout(() => {
        var inputs = document.querySelectorAll("#custom-range");
        for (let i = 0; i < inputs.length; i++) {
          const element = inputs[i];
          const max = element.dataset.max;
          const progress = (element.value / max) * 100;
          const gradient = `linear-gradient(to right, var(--greenn) ${progress}%, #E5E5E5 ${progress}%)`;

          element.style.background = gradient;
        }
      }, 0);
    },
  },
};
</script>

<style scoped lang="scss">
.reset-money {
  width: 40% !important;
  margin-bottom: 0px !important;
  height: auto !important;
  padding: 5px 20px !important;
}
.total {
  background: var(--greenn2);
  .lucro {
    color: var(--greenn);
    font-size: 16px;
    font-weight: 600;
  }
}
.off {
  background: #f7f7f7;
}
.container-label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .name {
    font-weight: 600;
    font-size: 16px;
  }
  .value {
    color: var(--greenn);
    font-size: 16px;
    font-weight: 600;
  }
  .off {
    color: #81858e;
    font-weight: 600;
  }

  @media (max-width: 768px) {
    .name {
      font-size: 14px;
    }
    .value {
      font-size: 13px;
    }
    .off {
      font-size: 14px;
    }
    img {
      width: 33px;
      height: 33px;
    }
  }
  img {
    width: 33px;
    height: 33px;
  }
}
.container-marketing {
  display: grid;
  gap: 10px;
  padding: 20px;
  border-radius: 7px;
  border: 1px solid #e5e5e5;
  height: 78px;
}
.container-all-marketing {
  display: grid;
  gap: 15px;
}
.simulator {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  .title {
    font-size: 22px;
    font-weight: 600;
  }

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    div {
      width: 100%;
    }
  }
}
input[type="range"] {
  border: none !important;
  padding: 0px !important;
  height: auto !important;
  /*   -webkit-appearance: none; */
  appearance: none !important;
  position: relative !important;
  width: 100% !important;
  height: 4px !important;
  border-radius: 15px !important;
  outline: none !important;
  margin: 0 !important;
}
//  Thumb: webkit
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none !important;
  height: 10px !important;
  width: 10px !important;
  background-color: var(--greenn) !important;
  border-radius: 50% !important;
  border: 2px solid var(--greenn) !important;
  transition: 0.2s ease-in-out !important;
}
/* Thumb: Firefox */
input[type="range"]::-moz-range-thumb {
  height: 10px !important;
  width: 10px !important;
  background-color: var(--greenn) !important;
  border-radius: 50% !important;
  border: 2px solid var(--greenn) !important;
  transition: 0.2s ease-in-out !important;
}
</style>
